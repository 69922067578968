import React, { useEffect, createContext, useContext, useState } from "react";
import { useLocation } from "react-router-dom";

// Create a context
const TawkToContext = createContext();

// Custom hook for using the TawkTo context
export const useTawkTo = () => {
  return useContext(TawkToContext);
};

// TawkToProvider component
export const TawkToProvider = ({ tawkToId, children }) => {
  const location = useLocation(); // Get the current path
  const [isHidden, setIsHidden] = useState(false); // Local state to track visibility

  useEffect(() => {
    if (!tawkToId) return;

    const hiddenPaths = [
      "/dashboard",
      "/admin_attendance_list",
      "/leaves",
      "/employee/requests",
      "/employees/report",
      "/employees/activity-tracking",
      "/visitor/list",
      "/holiday",
      "/approvals",
      "/setting",
      "/leave/management",
      "/leave/tracker",
      "/leave/adjustment",
      "/leave/setting",
      "/leave/leave-report",
      "/leave/balance",
      "/employees/list",
      "/employee-profile/change-password",
      "/profiles/profile",
      "/employee-dashboard",
      "/attendance",
      "/employee-leave",
      "/holiday-calendar",
    ];

    const isCurrentPathHidden = hiddenPaths.includes(location.pathname);
    setIsHidden(isCurrentPathHidden); // Update visibility state

    // Remove the TawkTo and language scripts if the path is hidden
    const removeTawkScripts = () => {
      const tawkScript = document.querySelector(`script[src*="${tawkToId}"]`);
      const langScript = document.querySelector(
        `script[src*="languages/en.js"]`
      );

      if (tawkScript) {
        document.body.removeChild(tawkScript);
      }

      if (langScript) {
        document.body.removeChild(langScript);
      }
    };

    if (isCurrentPathHidden) {
      removeTawkScripts();
      if (window.Tawk_API && typeof window.Tawk_API.hideWidget === "function") {
        window.Tawk_API.hideWidget();
      }
      return; // Exit early if hidden
    }

    // Create and append the TawkTo script if not hidden
    const script = document.createElement("script");
    script.src = `https://embed.tawk.to/${tawkToId}/1hn3195me`;
    script.async = true;
    script.setAttribute("crossorigin", "*");

    document.body.appendChild(script);

    // Set Tawk_API properties and event handlers
    window.Tawk_API = window.Tawk_API || {};

    const checkTawkApiAvailability = () => {
      if (window.Tawk_API) {
        window.Tawk_API.onBeforeLoad = function () {
          if (isHidden) {
            window.Tawk_API.hideWidget();
          }
        };

        window.Tawk_API.onChatMessageSystem = function () {
          if (!isHidden && typeof window.Tawk_API.showWidget === "function") {
            window.Tawk_API.showWidget();
          }
        };

        // Ensure widget visibility is managed according to isHidden state
        if (isHidden && typeof window.Tawk_API.hideWidget === "function") {
          window.Tawk_API.hideWidget();
        } else if (
          !isHidden &&
          typeof window.Tawk_API.showWidget === "function"
        ) {
          window.Tawk_API.showWidget();
        }
      }
    };

    script.onload = checkTawkApiAvailability;

    return () => {
      // Cleanup: remove both scripts when the component unmounts or path changes
      removeTawkScripts();
    };
  }, [tawkToId, location.pathname, isHidden]); // Added isHidden as a dependency

  return (
    <TawkToContext.Provider value={null}>{children}</TawkToContext.Provider>
  );
};
