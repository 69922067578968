import React, { useEffect, useRef, useState } from "react";
import "./DiscountBanner.css"; // Import custom styles
import AOS from "aos";
import "aos/dist/aos.css"; // Import AOS styles
import { color } from "echarts";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Button } from "@mui/material";
import AutoAwesomeRoundedIcon from "@mui/icons-material/AutoAwesomeRounded";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";

const DiscountBanner = () => {
  const navigate = useNavigate();
  const [claimtextMessage, setClaimtextMessage] = useState("");
  const [timeLeft, setTimeLeft] = useState(0);
  const [endTime, setEndTime] = useState(null);

  const fetchServerTime = async () => {
    try {
      const response = await fetch('https://timetango.in/api/application/versionlist/current-time');
      const data = await response.json();
      return { currentTime: data.currentTime, endTime: data.endTime };
    } catch (error) {
      console.error('Error fetching server time:', error);
      return { currentTime: Date.now(), endTime: Date.now() }; // Fallback to local time
    }
  };

  useEffect(() => {
    let countdownInterval;

    const initializeCountdown = async () => {
      const { currentTime: serverCurrentTime, endTime: fetchedEndTime } = await fetchServerTime();
      setEndTime(fetchedEndTime);

      const remainingTime = Math.max(0, Math.floor((fetchedEndTime - serverCurrentTime) / 1000));
      setTimeLeft(remainingTime);

      countdownInterval = setInterval(async () => {
        const { currentTime: newServerCurrentTime } = await fetchServerTime();
        const newRemainingTime = Math.max(0, Math.floor((fetchedEndTime - newServerCurrentTime) / 1000));
        
        console.log('Remaining Time:', newRemainingTime); // Log remaining time
        setTimeLeft(newRemainingTime);
      }, 1000);
    };

    if (endTime === null) {
      initializeCountdown();
    }

    return () => {
      clearInterval(countdownInterval);
    };
  }, [1000]);

  const formatTimeLeft = (time) => {
    const days = Math.floor(time / (3600 * 24));
    const hours = Math.floor((time % (3600 * 24)) / 3600);
    const minutes = Math.floor((time % 3600) / 60);
    const seconds = time % 60;

    return { days, hours, minutes, seconds };
  };

  const timeDisplay = formatTimeLeft(timeLeft);


  useEffect(() => {
    AOS.init({
      initClassName: false,
      useClassNames: true,
      duration: 900, // Animation duration
      once: false, // Animation happens only once
    });
  }, []);

  const handleClaimClick = (e) => {
    e.preventDefault();
    const claimtextMessage =
      "Festive Offer: Get 50% Off on Your First Year Subscription! – Grab It Now!"; // Define your message
    navigate("/company-register", { state: { claimtextMessage } }); // Pass the message using state
  };

  return (
    <div
      className="mb-0 text-center discount-banner pb-4"
      role="alert"
      variant="light"
      // style={{ textShadow: "25px 50px 100px #eb1b57" }}
    >
      <div className="banner-content" data-aos="fade-down">
        <h4 className="alert-heading">
          <AutoAwesomeRoundedIcon className="text-warning" /> Limited Time
          Offer! <AutoAwesomeRoundedIcon className="text-warning text-xl" />
        </h4>
        {/* <p className="offer-text">
          Kickstart your journey with us and enjoy a fantastic 50% off your
          first year!
        </p> */}

        <div className="timer">
          <strong> Hurry, offer ends in: </strong>
          <div className="time_block d-flex justify-content-evenly gap-0 mx-auto w-75 w-sm-100">
            <p className="time-left d-flex flex-column">
              <span>{timeDisplay?.days || '-'}</span> <small> Days </small>
            </p>
            <p className="time-left d-flex flex-column">
              <span>{timeDisplay?.hours || '-'}</span> <small> Hours </small>
            </p>
            <p className="time-left d-flex flex-column">
              <span>{timeDisplay?.minutes || '-'}</span> <small> Mins </small>
            </p>
            <p className="time-left d-flex flex-column">
              <span>{timeDisplay?.seconds || '-'}</span> <small> Sec </small>
            </p>
          </div>
        </div>

        {/* <h3 className="">Grab It Now</h3> */}
      </div>
      <div className="row justify-content-center btn_wrapper">
        <div className="col-md-6 col-12 w-tab-100">
          <Button
            variant="contained"
            className="popup_btn bg-contained"
            onClick={handleClaimClick}
          >
            Claim Your Discount
          </Button>
        </div>
        <div className="col-md-6 col-12 w-tab-100">
          <Button
            variant="contained"
            className="popup_btn popup_btn_outline"
            onClick={(e) => {
              e.preventDefault();
              navigate("/offer");
            }}
          >
            View offer
            <span>
              <ArrowForwardIcon className="arrow" />
            </span>
          </Button>
        </div>
      </div>
    </div>
  );
};

export default DiscountBanner;
