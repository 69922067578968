import { lazy } from "react";
import AuthGuard from "./auth/AuthGuard";
import { authRoles } from "./auth/authRoles";
import Loadable from "./components/Loadable";
import MatxLayout from "./components/MatxLayout/MatxLayout";
import LogInLayout from "./utils/LogInLayout";
import EmployeeGuard from "./auth/EmployeeGuard";
import CheckInOutGaurd from "./auth/CheckInOutGaurd";
import CompanyGuard from "./auth/CompanyGuard";
import AddLeaveType from "./views/leaveManagement/admin/AddEditLeaveType";
import OfferLanding from "./views/sessions/offer/OfferLanding";

// import materialRoutes from 'app/views/material-kit/MaterialRoutes';

// session pages
const NotFound = Loadable(lazy(() => import("app/views/sessions/NotFound")));
const JwtLogin = Loadable(lazy(() => import("app/views/sessions/JwtLogin")));
const JwtAdminLogin = Loadable(
  lazy(() => import("app/views/sessions/JwtAdminLogin"))
);
const JwtLRegisterCompany = Loadable(
  lazy(() => import("app/views/sessions/JwtLRegisterCompany"))
);
const JwtRegister = Loadable(
  lazy(() => import("app/views/sessions/JwtRegister"))
);
const ForgotPassword = Loadable(
  lazy(() => import("app/views/sessions/ForgotPassword"))
);
const AdminForgotPassword = Loadable(
  lazy(() => import("app/views/sessions/AdminForgotPassword"))
);

// echart page
const AppEchart = Loadable(
  lazy(() => import("app/views/charts/echarts/AppEchart"))
);
// comapony  page
const CompanyList = Loadable(lazy(() => import("app/views/company/list")));
const AddCompany = Loadable(lazy(() => import("app/views/company/add")));
// employees page
const EmployeesTable = Loadable(lazy(() => import("app/views/employees/list")));
const AddEmployee = Loadable(lazy(() => import("app/views/employees/add")));
const EditEmployee = Loadable(lazy(() => import("app/views/employees/edit")));
const AdminList = Loadable(lazy(() => import("app/views/employees/AdminList")));
// const TodayAttendaceList = Loadable(lazy(() => import("app/views/employees/attendance/todayList")));

// checkinout
const Checkinout = Loadable(
  lazy(() => import("app/views/checkinout/checkinout"))
);
const Checkoutlist = Loadable(
  lazy(() => import("app/views/checkinout/checkoutlist"))
);
const EmployeeAttendanceList = Loadable(
  lazy(() => import("app/views/checkinout/attendanceList"))
);
const EmployeeLeave = Loadable(
  lazy(() => import("app/views/checkinout/EmployeeLeave"))
);

// Roles
const RoleList = Loadable(lazy(() => import("app/views/roles/roles")));
const AddRole = Loadable(lazy(() => import("app/views/roles/AddRoles")));
const EditRole = Loadable(lazy(() => import("app/views/roles/EditRole")));
// const Checkoutlist = Loadable(lazy(() => import('app/views/checkinout/checkoutlist')));
const Approval = Loadable(lazy(() => import("app/views/Deskstop/ApproveList")));
// stting page
const Setting = Loadable(lazy(() => import("app/views/setting/Setting")));
//profile
const Profile = Loadable(lazy(() => import("app/views/profile/profile")));
const ChangePassword = Loadable(
  lazy(() => import("app/views/profile/ChangePassword"))
);

// dashboard page
const Analytics = Loadable(lazy(() => import("app/views/dashboard/Analytics")));
// Report Page
const EmployeeReport = Loadable(
  lazy(() => import("app/views/employees/report/Report"))
);
// Leave Page
const Leaves = Loadable(
  lazy(() => import("app/views/employees/leaves/LeaveTab"))
);
const LandingPage = Loadable(
  lazy(() => import("app/views/sessions/LandingPage"))
);

//vistor
const VisitorList = Loadable(lazy(() => import("app/views/visitor/list")));

//Holiday
const Holiday = Loadable(lazy(() => import("app/views/holiday/Holiday")));
const AddHoliday = Loadable(
  lazy(() => import("app/views/holiday/Add_Holiday"))
);
const HolidayCalender = Loadable(
  lazy(() => import("app/views/holiday/HolidayTab"))
);

//Employee Dashboard
const EmployeeDashboard = Loadable(
  lazy(() => import("app/views/employee-dashboard/employee-dashboard"))
);
//Employee calende
const EmployeeCalendar = Loadable(
  lazy(() => import("app/views/employee-calendar/employee-calendar"))
);

//Employee logs
const EmployeActivityTab = Loadable(
  lazy(() => import("app/views/employee-log/EmployeActivityTab"))
);

//System Dashbaord
const SystemDashboard = Loadable(
  lazy(() => import("app/views/system-dashboard/systemDashboard"))
);

//System Dashbaord
const Requests = Loadable(
  lazy(() => import("app/views/employees/requests/requests"))
);
// project label
const ProjectLabel = Loadable(
  lazy(() => import("app/views/project-label/project-label"))
);
//Leave Management system

const LeaveType = Loadable(
  lazy(() => import("app/views/leaveManagement/admin/LeaveType"))
);
const LeaveTracker = Loadable(
  lazy(() => import("app/views/leaveManagement/admin/LeaveTracker"))
);
const EmployeeLeaveList = Loadable(
  lazy(() => import("app/views/leaveManagement/admin/EmployeeLeaves"))
);
const LeaveCofiguration = Loadable(
  lazy(() => import("app/views/leaveManagement/admin/LeaveConfiguration"))
);

const LeaveReport = Loadable(
  lazy(() => import("app/views/leaveManagement/admin/LeaveReport"))
);

const EmployeeLeaveBalance = Loadable(
  lazy(() => import("app/views/leaveManagement/admin/EmployeeLeaveBalance"))
);


const routes = [
  {
    element: (
      <AuthGuard>
        <MatxLayout />
      </AuthGuard>
    ),
    children: [
      // ...materialRoutes,
      // dashboard route
      {
        path: "/dashboard",
        element: <Analytics />,
        auth: authRoles.admin,
      },
      {
        path: "/timesheet-list",
        element: <ProjectLabel />,
        auth: authRoles.admin,
      },

      // e-chart rooute
      {
        path: "/charts/echarts",
        element: <AppEchart />,
        auth: authRoles.editor,
      },
      {
        path: "/employees/list",
        element: <EmployeesTable />,
        // element: <Checkoutlist />,
        auth: authRoles.employee,
      },

      // {
      //   path: "/checkInOut",
      //   // element: <EmployeesTable />,
      //   element: <Checkinout />,
      //   auth: authRoles.employee,
      // },
      {
        path: "/admin_attendance_list",
        element: <AdminList />,
        // auth: authRoles.sa,
      },
      {
        path: "/employees/add",
        element: <AddEmployee />,
        auth: authRoles.editor,
      },
      {
        path: "/employees/edit",
        element: <EditEmployee />,
        auth: authRoles.editor,
      },
      {
        path: "/checkinout/checkoutlist",
        element: <Checkinout />,
        auth: authRoles.editor,
      },
      // {
      //   path: '/employees/edit',
      //   element: <Checkoutlist />,
      //   auth: authRoles.editor
      // },
      {
        path: "/profile/profile",
        element: <Profile />,
        auth: authRoles.editor,
      },
      {
        path: "/roles/roles",
        element: <RoleList />,
        auth: authRoles.editor,
      },
      {
        path: "/roles/add",
        element: <AddRole />,
        auth: authRoles.editor,
      },
      {
        path: "/roles/Edit",
        element: <EditRole />,
        auth: authRoles.editor,
      },
      {
        path: "/approvals",
        element: <Approval />,
        auth: authRoles.editor,
      },
      {
        path: "/employee/requests",
        element: <Requests />,
        auth: authRoles.editor,
      },
      {
        path: "/setting",
        element: <Setting />,
        auth: authRoles.editor,
      },
      {
        path: "/employees/report",
        element: <EmployeeReport />,
        auth: authRoles.editor,
      },
      {
        path: "/employees/activity-tracking",
        element: <EmployeActivityTab />,
        auth: authRoles.editor,
      },
      {
        path: "/leaves",
        element: <Leaves />,
        auth: authRoles.editor,
      },
      {
        path: "/visitor/list",
        element: <VisitorList />,
        auth: authRoles.editor,
      },
      {
        path: "/holiday",
        element: <HolidayCalender />,
        auth: authRoles.editor,
      },
      {
        path: "/holiday/add",
        element: <AddHoliday />,
        auth: authRoles.editor,
      },
      {
        path: "profile/change-password",
        element: <ChangePassword />,
        auth: authRoles.editor,
      },
      {
        path: "/leave/management",
        element: <LeaveType />,
      },
      {
        path: "/leave/tracker",
        element: <LeaveTracker />,
      },
      {
        path: "/leave/balance",
        element: <EmployeeLeaveBalance />,
      },
      {
        path: "/employee_leave",
        element: <EmployeeLeaveList />,
      },
      {
        path: "/leave/adjustment",
        element: <EmployeeLeaveList />
      },
        {
        path: "/leave/leave-report",
        element: <LeaveReport />,
      },
      {
        path: "/add/leavetype",
        element: <AddLeaveType />,
      },
      {
        path: "/leave/setting",
        element: <LeaveCofiguration />,
      },
      //Leave Management system
      {
        path: "/leave/management",
        element: <LeaveType />,
      },
      {
        path: "/leave/tracker",
        element: <LeaveTracker />,
      },
      {
        path: "/leave/adjustment",
        element: <EmployeeLeaveList />
      },
      {
        path: "/leave/leave-report",
        element: <LeaveReport />,
      },
      {
        path: "/add/leavetype",
        element: <AddLeaveType />,
      },
      {
        path: "/leave/setting",
        element: <LeaveCofiguration />,
      },
    ],
  },
  // Employee module routes
  {
    element: (
      <EmployeeGuard>
        <MatxLayout />
      </EmployeeGuard>
    ),
    children: [
      {
        path: "/employee-dashboard",
        element: <EmployeeDashboard />,
        // auth: authRoles.admin,
      },
      {
        path: "/profiles/profile",
        element: <Profile />,
        auth: authRoles.editor,
      },
      {
        path: "employee-profile/change-password",
        element: <ChangePassword />,
        auth: authRoles.editor,
      },
      {
        path: "/holiday-calendar",
        element: <EmployeeCalendar />,
        // auth: authRoles.admin,
      },
      {
        path: "/attendance",
        // element: <EmployeesTable />,
        element: <EmployeeAttendanceList />,
        auth: authRoles.employee,
      },
      {
        path: "/employee-leave",
        // element: <EmployeesTable />,
        element: <EmployeeLeave />,
        auth: authRoles.employee,
      },
    ],
  },
  // Company module routes
  {
    element: (
      <CompanyGuard>
        <MatxLayout />
      </CompanyGuard>
    ),
    children: [
      {
        path: "/admin/company/details",
        element: <AddCompany />,
        auth: authRoles.editor,
      },
      {
        path: "/system-dashboard",
        element: <SystemDashboard />,
        auth: authRoles.admin,
      },
      {
        path: "/admin/company",
        element: <CompanyList />,
        auth: authRoles.editor,
      },
    ],
  },
  // session pages route
  { path: "*", element: <NotFound /> },

  {
    element: (
      <CheckInOutGaurd>
        <MatxLayout />
      </CheckInOutGaurd>
    ),
    children: [
      {
        path: "/checkInOut",
        // element: <EmployeesTable />,
        element: <Checkinout />,
        auth: authRoles.employee,
      },
    ],
  },

  {
    path: "/",
    element: (
      // <LogInLayout>
      //   <LandingPage />
      // </LogInLayout>
      <LandingPage />
    ),
  },
  {
    path: "/login",
    element: (
      <LogInLayout>
        <JwtLogin />
      </LogInLayout>
    ),
  },

  {
    path: "/admin/login",
    element: (
      <LogInLayout>
        <JwtAdminLogin />
      </LogInLayout>
    ),
  },

  {
    path: "/forgot-password",
    element: (
      <LogInLayout>
        <ForgotPassword />
      </LogInLayout>
    ),
  },
  {
    path: "/admin-forgot-password",
    element: (
      <LogInLayout>
        <AdminForgotPassword />
      </LogInLayout>
    ),
  },
  {
    path: "/company-register",
    element: (
      <LogInLayout>
        <JwtLRegisterCompany />
      </LogInLayout>
    ),
  },
  {
    path: "/offer",
    element: (
      <LogInLayout>
        <OfferLanding />
      </LogInLayout>
    ),
  },

  // { path: '/', element: <Navigate to="dashboard/default" /> },
  { path: "*", element: <NotFound /> },
];

export default routes;
